/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var bootstrapModal = $.fn.modal.noConflict();
  $.fn.bootstrapModal = bootstrapModal;

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        // JavaScript to be fired on all pages

        // Nav menu toggle
        $('.nav-menu').bind('click', function(e) {
          e.preventDefault();
          $('#nav-icon').toggleClass('open');
          $('.header .menu-container').toggleClass('show');
        });

        var windowHeight = $( window ).height();
        $('.header.hero').css({
          minHeight: windowHeight - 20
        })
        $('.hero-home').css({
          bottom: 200 + Math.ceil((windowHeight - 713 - 50) / 2 )
        });

        // Homepage tabbed nav
        $('.tabbed-nav a').bind('click', function(e) {
          e.preventDefault();
          var i = $(this).index('.tabbed-nav a');
          var href = $(this).attr('href');
          if (href != '#') {
            // redirect to the url if not #
            window.location = href;
          } else {
            $(this)
              .parents('ul').find('li').removeClass('active').eq(i).addClass('active');
            $(this)
              .parents('section')
                .find('.tabbed-nav-section').removeClass('active').eq(i).addClass('active');
          }
        });

        $('.st_sharethis_custom').bind('click', function(e) {
          e.preventDefault();
        });

        // modal
        if($('#exampleModalLong').length) {
          if(localStorage.getItem('popState') != 'shown' || window.location.hash == '#modal') {
            $('#exampleModalLong').bootstrapModal('show');
          }
          $('#exampleModalLong').on('hidden.bs.modal', function(e) {
            localStorage.setItem('popState', 'shown')
          });
        }

        if($('.gallery').length) {

          // Scrolling carousel
          var flky = new Flickity('.gallery', {
            cellSelector: '.gallery-item',
            cellAlign: 'left',
            contain: true,
            initialIndex: 1,
            freeScroll: true,
            wrapAround: true,
            pageDots: false
          });

          // Set the data-sub-html so that captions work
          $('.gallery-item').each(function(i, el) {
            var img = $(el).find('img');
            var captionId = $(img).attr('aria-describedby');
            if (captionId) {
              var a = $(el).find('a');
              $(a).attr('data-sub-html', '#' + captionId);
            }
          });

          // Full page lightbox gallery
          $(".gallery").lightGallery({
            selector: '.gallery-item a'
          });

        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single_listing': {
      init: function() {

        // Click on the featured image, launch the gallery
        $('.single-listing-image-link').bind('click', function(e) {
          e.preventDefault();
          $('#listing-gallery .gallery-item a:first').click();
        });

        // Toggle for the virtual tour
        $('.toggle-virtual-tour').bind('click', function(e) {
          e.preventDefault();
          $('#listing-video').toggleClass('active');
        });

        // Initialize the map
        $('.tabbed-nav a:last').bind('click', function(e) {
          if(googlemapLoaded === false) {
            initialize();
            googlemapLoaded = true;
          }
        });

      }
    },
    // Neighborhoods
    'neighborhoods_of_expertise': {
      init: function() {

       // Initialize the map object
        var map = new L.Map('map', {
            // Some basic options to keep the map still and prevent 
            // the user from zooming and such.
            scrollWheelZoom: false,
            touchZoom: false,
            doubleClickZoom: false,
            zoomControl: false,
            dragging: false
        });
        // Prep the background tile layer graciously provided by stamen.com
        var stamenUrl = 'http://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png';
        var stamenAttribution = 'Tiles courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap Sweden</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
        //var stamenUrl = 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
        //var stamenAttribution = 'Tiles courtesy of <a href="https://www.mediawiki.org/wiki/Maps" target="_blank">Wikimedia Maps</a>';
        var stamenLayer = new L.TileLayer(stamenUrl, {maxZoom: 18, attribution: stamenAttribution});
        // Set the center on our city of angels
        var center = new L.LatLng(47.6097, -122.33);
        map.setView(center, 13);
        // Load the background tiles
        map.addLayer(stamenLayer);
        // Create an empty layer where we will load the polygons
        var featureLayer = new L.GeoJSON();
        // Set a default style for out the polygons will appear
        var defaultStyle = {
            color: "#2262CC",
            weight: 2,
            opacity: 0.6,
            fillOpacity: 0.1,
            fillColor: "#2262CC"
        };
        var highlightStyle = {
            color: '#2262CC', 
            weight: 3,
            opacity: 0.6,
            fillOpacity: 0.65,
            fillColor: '#2262CC'
        };
        // Define what happens to each polygon just before it is loaded on to
        // the map. This is Leaflet's special way of goofing around with your
        // data, setting styles and regulating user interactions.
        var onEachFeature = function(feature, layer) {
            // Load the default style. 
            layer.setStyle(defaultStyle);
            // Create a self-invoking function that passes in the layer
            // and the properties associated with this particular record.
            (function(layer, properties) {
              // Create a mouseover event
              layer.on("mouseover", function (e) {
                // Change the style to the highlighted version
                layer.setStyle(highlightStyle);
                // Create a popup with a unique ID linked to this record
                var popup = $("<div></div>", {
                    id: "popup-" + properties.filename,
                    css: {
                        position: "absolute",
                        bottom: "85px",
                        left: "50px",
                        zIndex: 1002,
                        backgroundColor: "white",
                        padding: "10px 20px",
                        fontSize: "30px",
                        fontFamily: 'Didot W01 Italic',
                        border: "1px solid #ccc"
                    }
                });
                // Insert a headline into that popup
                var hed = $("<div></div>", {
                    text: properties.name,
                    css: {fontSize: "16px", marginBottom: "3px"}
                }).appendTo(popup);
                // Add the popup to the map
                popup.appendTo("#map");
              });
              // Create a mouseout event that undoes the mouseover changes
              layer.on("mouseout", function (e) {
                // Start by reverting the style back
                layer.setStyle(defaultStyle); 
                // And then destroying the popup
                $("#popup-" + properties.filename).remove();
              });
              layer.on("click", function(e) {
                window.location = properties.link;
              });
              // Close the "anonymous" wrapper function, and call it while passing
              // in the variables necessary to make the events work the way we want.
            })(layer, feature.properties);
        };

        var features = [];

        // load are files from geojson from
        // https://github.com/craic/zillow_seattle_neighborhoods
        var loadArea = function(filename, label, link) {
          var deferred = $.Deferred();
          $.ajax({
            url: "/wp-content/themes/urbanash/assets/scripts/geojson/" + filename + ".geojson",
            type: 'get',
            dataType: 'json',
            success: function(data) {
              data['properties'] = {
                filename: filename,
                name: label,
                link: link
              };
              //console.log('adding', filename);
              features.push(data);
              deferred.resolve();
            }
          });
          return deferred.promise();
        };

        $.when(
          loadArea('belltown',
                   'Belltown',
                   '/neighborhood/belltown/'),
          loadArea('beacon_hill',
                   'Beacon Hill',
                   '/neighborhood/beacon-hill/'),
          loadArea('capitol_hill',
                   'Capitol Hill',
                   '/neighborhood/capitol-hill/'),
          loadArea('downtown',
                   'Downtown',
                   '/neighborhood/downtown/'),
          loadArea('international_district',
                   'International District',
                   '/neighborhood/international-district/'),
          loadArea('lower_queen_anne',
                   'Lower Queen Anne',
                   '/neighborhood/lower-queen-anne-seattle/'),
          loadArea('magnolia',
                   'Magnolia',
                   '/neighborhood/magnolia/'),
          loadArea('pioneer_square',
                   'Pioneer Square',
                   '/neighborhood/pioneer-square/'),
          loadArea('queen_anne',
                   'Queen Anne',
                   '/neighborhood/queen-anne/'),
          loadArea('south_lake_union',
                   'South Lake Union',
                   '/neighborhood/south-lake-union/'),
          loadArea('alki',
                   'West Seattle / Alki',
                   '/neighborhood/west-seattle/'),
          loadArea('admiral',
                   'West Seattle / Alki',
                   '/neighborhood/west-seattle/')
        ).then(function(a, b, c) {
          // Add the GeoJSON to the layer. `boundaries` is defined in the external
          // GeoJSON file that I've loaded in the <head> of this HTML document.
          var featureLayer = L.geoJson(features, {
              // And link up the function to run when loading each feature
              onEachFeature: onEachFeature
          });
          // Finally, add the layer to the map.
          map.addLayer(featureLayer);
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // realscout search
        $("#realscout-widget").submit(function(e) {
          e.preventDefault();
          var price_min = $(this).find('select[name="price_min"]').val(),
              price_max = $(this).find('select[name="price_max"]').val(),
              bedrooms_min = $(this).find('select[name="bedrooms_min"]').val(),
              bathrooms_min = $(this).find('select[name="bathrooms_min"]').val();
          window.open('https://pointe3.realscout.com/search#cats_/pmax_' + price_max + '/pmin_' + price_min + '/bmin_' + bedrooms_min + '/bamin_' + bathrooms_min + '/clat_47.58413310678356/clng_-122.2107912689209/zm_12/');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'rentals': {
      init: function() {
        // JavaScript to be fired on the about us page

        function doPageLoad(){
          console.log('initSearentSearch');
          initSearentSearch('detail_test.html', nid, tid, '0', minPrice, maxPrice, '1335');
        };
        function searentUnitDetailOnClickOverride(strUrl, iUnitId, strTitle){
          //$.fn.colorbox({href:strUrl, width:'875px', height:'95%', iframe:true, open:true});
          window.open(strUrl);
        };
        nid = 0;
        tid = 0;
        minPrice = 0;
        maxPrice = 99999;
        doPageLoad();
        $('#api_navigation li a').bind('click', function(e) {
          var href = $(this).attr('href')
          if(href.indexOf('http') > -1) {
            window.open(href);
          }
        });
        $('#api_navigation li a.filter').bind('click', function(e) {
          e.preventDefault();
          $('#api_navigation li').removeClass('active');
          $(this).parents('li').addClass('active');

          g_iPageNum = '1';
          if($(this).attr('id') == 'mine'){
            initSearentSearch('detail_test.html', nid, tid, '0', minPrice, maxPrice, '1335');
          }
          if($(this).attr('id') == 'srg'){
            initSearentSearch('detail_test.html', nid, tid, '0', minPrice, maxPrice, '');
          }
          return false;
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
